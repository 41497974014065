import React from "react";
import ReactApexChart from "react-apexcharts";

function Chart({ paymentHistory }) {
  let paymentDates = [];
  for (let i = 0; i < paymentHistory.length; i++) {
    const newDate = new Date(paymentHistory[i].datePaid);
    let localDate = newDate.toISOString().split('T')[0]
    paymentDates = [...paymentDates, localDate];
  }

  let paymentReceived = [];
  for (let i = 0; i < paymentHistory.length; i++) {
    paymentReceived = [
      ...paymentReceived,
      parseFloat(paymentHistory[i].amountPaid).toFixed(2),
    ];
  }

  const series = [
   
    {
      name: "Payment Received",
      data: paymentReceived,
    },
   
  ];
  const options = {
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#34A853"], // You can specify any color here
    plotOptions: {
      bar: {
        distributed: false,
      },
    },

    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: paymentDates,
    },
    tooltip: {
      x: {
        format: "dd-MMM-yyyy",
      },
    },
  };


  return (
    <div
      style={{
        backgroundColor: "white",
        textAlign: "center",
        width: "90%",
        margin: "10px auto",
        padding: "10px",
        borderBottom: "1px solid rgb(184, 184, 184)",
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        borderRadius: "12px",
      }}
    >
      <h1 style={{ textAlign: "center", padding: "30px" }}>
        Payment Statistics
      </h1>
      <br />
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={650}
      />
    </div>
  );
}

export default Chart;
