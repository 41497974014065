import React from "react";
import ReactApexChart from "react-apexcharts";

const Donut = ({ unpaid, paid, partial }) => {
  const series = [paid.length, unpaid.length, partial.length];
  const options = {
    chart: {
      type: "donut",
    },
    labels: ["Paid Invoices", "Unpaid Invoices", "Partially Paid"],
    colors: ["#34A853", "#EA4335", "#FBBC05"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "top",
          },
        },
      },
    ],
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        textAlign: "center",
        width: "90%",
        margin: "10px auto",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={650}
        
      />
    </div>
  );
};

export default Donut;
