import React, {  useEffect } from "react";
import styles from "./Payments.module.css";
import { useSnackbar } from "react-simple-snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceById } from "../../actions/invoiceActions";
import Spinner from "../Spinner/Spinner";
import moment from "moment";
import axios from "axios";

import Empty from "../svgIcons/Empty";
import { toCommas } from "../../utils/utils";

const Payment = () => {
  const [openSnackbar] = useSnackbar();
  const { invoice } = useSelector((state) => state.invoices);
  const { isLoading } = useSelector((state) => state.invoices);
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInvoiceById(id))
  }, [id, dispatch]);


  const paymentAmount = invoice?.totalAmountReceived?invoice?.total-invoice?.totalAmountReceived:invoice?.total;

  console.log(paymentAmount)
  const handlePayment = async (invoice) => {
    const paymentData = {
      amount: paymentAmount,
      currency: invoice.currency,
      razorpayKey:invoice?.profile?.razorpayKey,
      razorpaySecret:invoice?.profile?.razorpaySecret
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {
      data: { order },
    } = await axios.post(
      `${process.env.REACT_APP_RAZORPAY_CHECKOUT}`,
      paymentData,
      config
    );



    const options = {
      key: invoice.profile.razorpayKey,
      amount: Number(paymentAmount) * 100,
      currency: invoice.currency,
      name: invoice.profile.businessName,
      description: invoice.items[0].itemName + "\n...",
      image: invoice.profile.logo,
      order_id: order.id,

      prefill: {
        name: invoice?.client?.name,
        email: invoice?.client?.email,
        contact: invoice?.client?.phone,
      },

      notes: {
        address: invoice?.profile?.contactAddress,
      },
      theme: {
        color: "#607ffe",
      },
      handler: async function (response) {
        const { razorpay_payment_id } = response;
        // Fetch payment details using the payment ID

        const {
          data: { paymentDetails },
        } = await axios.get(
          `${process.env.REACT_APP_RAZORPAY_DETAILS}/${razorpay_payment_id}/${invoice?.profile?.razorpayKey}/${invoice?.profile?.razorpaySecret}`,
          config
        );

        

        const body = {
          ...response,
          paymentDetails,
          invoice,
        };
        
        const { data } = await axios.post(
          `${process.env.REACT_APP_RAZORPAY_VALIDATE}/${invoice?.profile?.razorpaySecret}`,
          body,
          config
        );

        if (data.status === "success") {
          openSnackbar(data.message);
          history(`/payment/success/${id}`);
        }
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  if (isLoading) {
    return <Spinner />;
  }


  if(invoice?.status === "Paid"){
    history(`/payment/success/${id}`);
  }

  if (!invoice ) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        {/* <Spinner /> */}
        <Empty />
        <h2 style={{ padding: "40px", color: "gray" }}>
          No Invoice Found
        </h2>
      </div>
    );
  } else {
    return (
      <div className={styles.pageContainer}>
        <section className={styles.hero}>
          <h1 className={styles.heroTitle}>Hey, {invoice?.client?.name}👋</h1>

          <h2 className={styles.heroSubTitle}>
            Your {invoice?.type} is ready. Please pay {invoice?.currency}{" "}
            {toCommas(paymentAmount)}
          </h2>
          <h2 className={styles.heroSubTitle}>
            before {moment(invoice?.dueDate).format("ll")} to avoid late fees.
          </h2>

          <div className={styles.header2}>
            <button
              onClick={() => handlePayment(invoice)}
              className={styles.login}
            >
              Pay Now
            </button>
          </div>
        </section>
      </div>
    );
  }
};

export default Payment;
