import React, { useEffect, useState } from "react";
import styles from "./Payments.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceById } from "../../actions/invoiceActions";
import ProgressButton from "react-progress-button";
import moment from "moment";
import Spinner from "../Spinner/Spinner";
import { toCommas } from "../../utils/utils";
import axios from "axios";
import { useSnackbar } from "react-simple-snackbar";

const PaymentSuccess = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [openSnackbar] = useSnackbar();
  const { invoice } = useSelector((state) => state.invoices);
  const { isLoading } = useSelector((state) => state.invoices);

  const [downloadStatus, setDownloadStatus] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInvoiceById(id));
  }, [id, dispatch]);

  if (invoice?.status !== "Paid") {
    history(`/payment/${id}`);
  }

  const createAndDownloadPdf = () => {
    setDownloadStatus("loading");
    const ids = `invoice-${invoice.client.name}-${invoice.invoiceNumber}.pdf`;
    axios
      .post(`${process.env.REACT_APP_INVOICE_API}/api/v2/invoices/generate`, {
        ids: invoice._id,
        name: invoice.client.name,
        gstno: invoice.client.gstno,
        flat: invoice.client.flat,
        flatarea: invoice.client.flatarea,
        floor: invoice.client.floor,
        block: invoice.client.block,
        phone: invoice.client.phone,
        email: invoice.client.email,
        dueDate: invoice.dueDate,
        date: invoice.createdAt,
        id: invoice.invoiceNumber,
        notes: invoice.notes,
        subTotal: toCommas(invoice.subTotal),
        total: toCommas(invoice.total),
        type: invoice.type,
        vat: invoice.vat,
        hsn: invoice.hsn,
        items: invoice.items,
        status: invoice.status,
        currency: invoice.currency,
        totalAmountReceived: toCommas(invoice.totalAmountReceived),
        balanceDue: toCommas(invoice.total - invoice.totalAmountReceived),
        company: invoice.profile,
      })
      .then((response) => {
        if (!response.data || response.data.status !== "success") {
          throw new Error("No data received or operation failed");
        }

        const downloadUrl = response.data.url;

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", ids);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadStatus("success");
      })
      .catch((error) => {
        setDownloadStatus("error");
        // Enhanced error handling

        console.error(error.message);
      });
  };

  const paymentDate =
    invoice?.status === "Paid"
      ? moment(invoice?.items[0]?.datePaid).format("ll")
      : moment(new Date()).format("ll");

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.pageContainer}>
      <section className={styles.hero}>
        <h1 className={styles.heroTitle}>
          Payment {invoice?.status === "Paid" ? "Successful 🥳" : "Failed 🥲"}
        </h1>

        <h2 className={styles.heroSubTitle}>
          Your {invoice?.type} has been {invoice?.status} at {paymentDate}
        </h2>
        <h2 className={styles.heroSubTitle}>
          You can download the invoice from here.
        </h2>

        <div className={styles.header2}>
          <ProgressButton
            onClick={createAndDownloadPdf}
            state={downloadStatus}
            onSuccess={() => openSnackbar("Download successfully")}
          >
            Download as PDF
          </ProgressButton>
          
        </div>
      </section>
    </div>
  );
};

export default PaymentSuccess;
