/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { useDispatch, useSelector } from "react-redux";
import { createClient, updateClient } from "../../actions/clientActions";
import { useSnackbar } from "react-simple-snackbar";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#1976D2",
    marginLeft: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddClient = ({ setOpen, open, currentId, setCurrentId }) => {
  const location = useLocation();
  const [clientData, setClientData] = useState({
    name: "",
    gstno: "",
    email: "",
    phone: "",
    floor: "",
    flatarea: "",
    price:"",
    block: "",
    flat: "",
    userId: "",
  });
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const dispatch = useDispatch();
  const client = useSelector((state) =>
    currentId ? state.clients.clients.find((c) => c._id === currentId) : null
  );
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();

  useEffect(() => {
    if (client) {
      setClientData(client);
    }
  }, [client]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
    // setClientData({...clientData, userId: user?.result?._id})
  }, [location]);

  useEffect(() => {
    var checkId = user?.result?._id;
    if (checkId !== undefined) {
      setClientData({ ...clientData, userId: [checkId] });
    } else {
      setClientData({ ...clientData, userId: [user?.result?.googleId] });
    }
  }, [location]);

  const handleSubmitClient = (e) => {
    e.preventDefault();
    if (currentId) {
      dispatch(updateClient(currentId, clientData, openSnackbar));
    } else {
      dispatch(createClient(clientData, openSnackbar));
    }

    clear();
    handleClose();
  };

  const clear = () => {
    setCurrentId(null);
    setClientData({
      name: "",
      gstno: "",
      email: "",
      phone: "",
      floor: "",
      flatarea: "",
      price:"",
      block: "",
      flat: "",
      userId: [],
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const inputStyle = {
    display: "block",
    padding: "1.4rem 0.75rem",
    width: "100%",
    fontSize: "0.8rem",
    lineHeight: 1.25,
    color: "#55595c",
    backgroundColor: "#fff",
    backgroundImage: "none",
    backgroundClip: "padding-box",
    borderTop: "0",
    borderRight: "0",
    borderBottom: "1px solid #eee",
    borderLeft: "0",
    borderRadius: "3px",
    transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)",
  };

  return (
    <div>
      <form>
        <Dialog
          onClose={(e, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            style={{ paddingLeft: "20px", color: "white" }}
          >
            {currentId ? "Edit Customer" : "Add New Client"}
          </DialogTitle>
          <DialogContent dividers>
            <div className="customInputs">
              <input
                placeholder="Name or Company name"
                style={inputStyle}
                name="name"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, name: e.target.value })
                }
                required
                value={clientData.name}
              />
              <input
                placeholder="GST number"
                style={inputStyle}
                name="gstno"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, gstno: e.target.value })
                }
                required
                value={clientData.gstno}
              />

              <input
                placeholder="Email"
                style={inputStyle}
                name="email"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, email: e.target.value })
                }
                required
                value={clientData.email}
              />

              <input
                placeholder="Enter Phone Number"
                style={inputStyle}
                name="phone"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, phone: e.target.value })
                }
                required
                value={clientData.phone}
              />

              <input
                placeholder="Enter Flat Area (Sq.ft)"
                style={inputStyle}
                name="flatarea"
                type="number"
                onChange={(e) =>
                  setClientData({ ...clientData, flatarea: e.target.value })
                }
                required
                value={clientData.flatarea}
              />
              <input
                placeholder="Enter Price per Sq.ft"
                style={inputStyle}
                name="price"
                type="number"
                onChange={(e) =>
                  setClientData({ ...clientData, price: e.target.value })
                }
                required
                value={clientData.price}
              />
              <input
                placeholder="Enter Block"
                style={inputStyle}
                name="block"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, block: e.target.value })
                }
                required
                value={clientData.block}
              />
              <input
                placeholder="Enter Floor"
                style={inputStyle}
                name="floor"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, floor: e.target.value })
                }
                required
                value={clientData.floor}
              />
              <input
                placeholder="Enter Flat No."
                style={inputStyle}
                name="flat"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, flat: e.target.value })
                }
                readOnly
                value={clientData.flat}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSubmitClient}
              variant="contained"
              style={{ marginRight: "25px" }}
            >
              Save Customer
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default AddClient;
