import * as api from "../api/index";

import {
  ADD_NEW,
  UPDATE,
  DELETE,
  GET_INVOICE,
  FETCH_INVOICE_BY_USER,
  START_LOADING,
  END_LOADING,
  FETCH_INVOICES_DATA,
  
} from "./constants";

export const getInvoicesByUser = (searchQuery) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));
  try {
    dispatch({ type: START_LOADING });
    
    const {
      data: { data },
    } = await api.fetchInvoicesByUser(searchQuery);

    const business = await api.fetchProfilesByUser({
      search: user?.result?._id || user?.result?.googleId,
    });

    dispatch({ type: FETCH_INVOICE_BY_USER, payload: { data, business } });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response);
  }
};
export const getAllInvoicesByUser = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));
  try {
    dispatch({ type: START_LOADING });
    
    const {
      data: { data },
    } = await api.fetchAllInvoicesByUser(id);

    const business = await api.fetchProfilesByUser({
      search: user?.result?._id || user?.result?.googleId,
    });

    dispatch({ type: FETCH_INVOICES_DATA, payload: { data, business } });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response);
  }
};

export const getOverduesInvoiceByUser = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data },
    } = await api.fetchOverDueInvoicesByUser(id);

    const business = await api.fetchProfilesByUser({
      search: user?.result?._id || user?.result?.googleId,
    });

    dispatch({ type: FETCH_INVOICES_DATA, payload: { data, business } });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response);
  }
};
export const getUnpaidInvoiceByUser = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data },
    } = await api.fetchUnpaidInvoicesByUser(id);

    const business = await api.fetchProfilesByUser({
      search: user?.result?._id || user?.result?.googleId,
    });

    dispatch({ type: FETCH_INVOICES_DATA, payload: { data, business } });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response);
  }
};
export const getPartialInvoiceByUser = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data },
    } = await api.fetchPartialInvoicesByUser(id);

    const business = await api.fetchProfilesByUser({
      search: user?.result?._id || user?.result?.googleId,
    });

    dispatch({ type: FETCH_INVOICES_DATA, payload: { data, business } });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response);
  }
};
export const getpaidInvoiceByUser = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data },
    } = await api.fetchPaidInvoicesByUser(id);

    const business = await api.fetchProfilesByUser({
      search: user?.result?._id || user?.result?.googleId,
    });

    dispatch({ type: FETCH_INVOICES_DATA, payload: { data, business } });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response);
  }
};


export const getInvoice = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));

  try {
    const { data } = await api.fetchInvoice(id);
    const businessDetails = await api.fetchProfilesByUser({
      search: user?.result?._id || user?.result?.googleId,
    });
    const invoiceData = { ...data, businessDetails };

    dispatch({ type: GET_INVOICE, payload: invoiceData });
  } catch (error) {
    console.log(error.response);
  }
};



export const getInvoiceById = (id)=>async(dispatch)=>{
  try{
    dispatch({ type: START_LOADING });
    const {data} = await api.fetchInvoice(id)
    const {data: {profile}} = await api.fetchProfilesByUserId(data.creator[0]);
    const invoiceData = { ...data, profile };
    
    dispatch({type:GET_INVOICE,payload:invoiceData});
    dispatch({ type: END_LOADING });

  }catch (error){

    if (error.response.data.status===404) {
      dispatch({ type: END_LOADING });
    }
    console.log(error.response);
  }
      
}


export const updatePaymentHistory =
  (id, recordId, openSnackbar) => async () => {
    try {
      const {
        data: { message },
      } = await api.deletePaymentHistory(id, recordId);
      window.location.reload();
      openSnackbar(`${message}`);
    } catch (error) {
      openSnackbar(`${error.response.message}`);
    }
  };

export const sentRemainder = (openSnackbar) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { message },
    } = await api.sentRemainder(user?.result?._id);
    dispatch({ type: END_LOADING });
    openSnackbar(`${message}`);
  } catch (error) {
    openSnackbar(`${error.response.message}`);
    console.log(error.response.message);
  }
};
export const sentWARemainder = (openSnackbar) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { message },
    } = await api.sentWARemainder(user?.result?._id , user?.userProfile?.token);
    dispatch({ type: END_LOADING });
    openSnackbar(`${message}`);
  } catch (error) {
    dispatch({ type: END_LOADING });
    if (error.response && error.response.data && error.response.data.message) {
      openSnackbar(`${error.response.data.message}`);
      
    } else {
      openSnackbar("An error occurred while sending the reminder.");
      
    }
  }
};

export const createInvoice =
  (invoice, snackbar, navigate) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.addInvoice(invoice);
      dispatch({ type: ADD_NEW, payload: data });
      navigate(`/invoice/${data._id}`);
      snackbar("Invoice generated successfully");
      dispatch({ type: END_LOADING });
    } catch (error) {
      snackbar(`Error: ${error.error}`);
      console.log(`Error: ${error.error}`);
    }
  };

export const updateInvoice = (id, invoice) => async (dispatch) => {
  try {
    const { data } = await api.updateInvoice(id, invoice);
    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    console.log(error);
  }
};


export const overDuesInvoice = (invoice) => async (dispatch) => {
  try {
    const {data:{data,id}} = await api.overDuesInvoice(invoice);

    console.log(data)
    dispatch({ type: UPDATE, payload: data });

    window.location.href = `/invoice/${id}`
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteInvoice = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteInvoice(id);

    dispatch({ type: DELETE, payload: id });
    openSnackbar("Invoice deleted successfully");
  } catch (error) {
    console.log(error.response);
  }
};
