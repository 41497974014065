import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducers from "./reducers/";

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

disableReactDevTools();

//
const store = createStore(reducers, composeWithDevToolsDevelopmentOnly(applyMiddleware(thunk)));




ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
