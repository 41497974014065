import axios from 'axios'
//const API = axios.create({ baseURL: 'http://localhost:4001'}) //process.env.REACT_APP_API
const API = axios.create({ baseURL:process.env.REACT_APP_API })

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
    }

    return req
})

// export const fetchInvoices =() => API.get('/api/v2/invoices')
export const fetchInvoice =(id) => API.get(`/api/v2/invoices/${id}`)
export const addInvoice =( invoice ) => API.post('/api/v2/invoices', invoice)
export const updateInvoice = (id, updatedInvoice) => API.patch(`/api/v2/invoices/${id}`, updatedInvoice)
export const overDuesInvoice = (updatedInvoice) => API.post(`/api/v2/invoices/overdue`, updatedInvoice)
export const deletePaymentHistory = (id, recordId) => API.delete(`/api/v2/invoices/payment/${id}/${recordId}`)
export const deleteInvoice =(id) => API.delete(`/api/v2/invoices/${id}`)
export const fetchInvoicesByUser = (searchQuery) => API.get(`/api/v2/invoices?searchQuery=${searchQuery.search}`);
export const fetchUnpaidInvoicesByUser = (id) => API.get(`/api/v2/invoices/unpaid/${id}`);
export const fetchAllInvoicesByUser = (id) => API.get(`/api/v2/invoices/all/${id}`);
export const fetchPaidInvoicesByUser = (id) => API.get(`/api/v2/invoices/paid/${id}`);
export const fetchPartialInvoicesByUser = (id) => API.get(`/api/v2/invoices/partial/${id}`);
export const fetchOverDueInvoicesByUser = (id) => API.get(`/api/v2/invoices/overdues/${id}`);
export const sentRemainder = (id) => API.post(`/api/v2/invoices/remainder/${id}`);
export const sentWARemainder = (id,token) => API.post(`/api/v2/invoices/warem/${id}/${token}`);


export const fetchClient = (id) => API.get(`/api/v2/clients/${id}`);
export const fetchClients = (page) => API.get(`/api/v2/clients?page=${page}`);
export const addClient =( client ) => API.post('/api/v2/clients', client)
export const updateClient = (id, updatedClient) => API.patch(`/api/v2/clients/${id}`, updatedClient)
export const deleteClient =(id) => API.delete(`/api/v2/clients/${id}`)
export const fetchClientsByUser = (searchQuery) => API.get(`/api/v2/clients/user?searchQuery=${searchQuery.search}`);

export const signIn =(formData)=> API.post('/api/v2/users/signin', formData)
export const signUp =(formData)=> API.post('/api/v2/users/signup', formData)
export const forgot = (formData) => API.post('/api/v2/users/forgot', formData);
export const reset = (formData) => API.post('/api/v2/users/reset', formData);

export const fetchProfilesBySearch = (searchQuery) => API.get(`/api/v2/profiles/search?searchQuery=${searchQuery.search || searchQuery.year || 'none'}`);
export const fetchProfile = (id) => API.get(`/api/v2/profiles/${id}`)
export const fetchProfiles = () => API.get('/api/v2/profiles');
export const fetchProfilesByUser = (searchQuery) => API.get(`/api/v2/profiles?searchQuery=${searchQuery.search}`)
export const fetchProfilesByUserId = (id) => API.get(`/api/v2/profiles/receiver/${id}`)
export const createProfile = (newProfile) => API.post('/api/v2/profiles', newProfile);
export const updateProfile = (id, updatedProfile) => API.patch(`/api/v2/profiles/${id}`, updatedProfile);
export const deleteProfile = (id) => API.delete(`/api/v2/profiles/${id}`);