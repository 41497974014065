import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Fab, Action } from 'react-tiny-fab';
import { useSnackbar } from "react-simple-snackbar";
import 'react-tiny-fab/dist/styles.css';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import RemaiderIcon from '@material-ui/icons/MailRounded';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddClient from '../Invoice/AddClient';
import { useDispatch } from 'react-redux';
import { sentRemainder, sentWARemainder } from '../../actions/invoiceActions';


const FabButton = () => {

  const user = JSON.parse(localStorage.getItem("profile"));
  const location = useLocation()
  const dispatch = useDispatch()
  const mainButtonStyles = {backgroundColor: '#1976D2'}
  const [open, setOpen] = useState(false)
  const [openSnackbar] = useSnackbar();
  

  const onSendRemainder =(e)=>{
    e.preventDefault();
    dispatch(sentRemainder(openSnackbar))
  }
  const onSendWARemainder =(e)=>{
    e.preventDefault();
    dispatch(sentWARemainder(openSnackbar))
  }



  



  // if(location.pathname === '/invoice') return null

    return (
        <div>
           <AddClient setOpen={setOpen} open={open} />
          <Fab
            mainButtonStyles={mainButtonStyles}
            icon={ <AddIcon />}
            alwaysShowTitle={true}
          >

            {
              location.pathname === '/overdue' && (
                <Action
                    text="Send Remainder"
                    onClick={onSendRemainder}
                  >
                    <RemaiderIcon />
                </Action>
              )
            }
            {
              location.pathname === '/overdue' && user?.userProfile?.token && (
                <Action
                    text="Remainder Via Whatsapp"
                    onClick={onSendWARemainder}
                  >
                    <WhatsappIcon />
                </Action>
              )
            }
            {

            }
            {location.pathname !== '/invoice' && (user?.userProfile?.razorpayKey||user?.userProfile?.razorpaySecret) && (


              
              <Action
                  text="New Invoice"
                  // onClick={() =>  history.push(`/invoice`)}
                  onClick={() => window.location.href='/invoice'}
                >
                  <CreateIcon />
              </Action>
            )}

            <Action
                text="New Customer"
                onClick={() => setOpen((prev) => !prev)}
              >
                <PersonAddIcon />
            </Action>

          </Fab>
        </div>
    )
}

export default FabButton
