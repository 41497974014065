import React from "react";
import { Container, Typography, Button, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@material-ui/icons/ErrorRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(3),
  },

  icon: {
    fontSize: "6rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "8rem",
    },
  },

  subtitle: {
    fontSize: "1.8rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
    },
  },
  button: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.5, 3),
  },
}));

const NotFound = () => {
  const classes = useStyles();
  const history = useNavigate();
 
  return (
    <>
      <Container className={classes.root}>
        <ErrorOutlineIcon className={classes.icon} color="error" />
        <Typography className={classes.subtitle} color="textSecondary">
          Page Not Found
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={()=> history("/dashboard")}
        >
          Home
        </Button>
      </Container>
    </>
  );
};

export default NotFound;
