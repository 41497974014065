import React from "react";
import moment from "moment";
import { toCommas } from "../../utils/utils";
import styles from "./InvoiceDetails.module.css";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import { IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updatePaymentHistory } from "../../actions/invoiceActions";
import { useParams } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";

const PaymentHistory = ({ paymentRecords }) => {
  const [openSnackbar] = useSnackbar();
  const dispatch = useDispatch();

  const { id } = useParams();

  const deletePayHis = (id, recordId) => {
    dispatch(updatePaymentHistory(id, recordId, openSnackbar));
  };

  return (
    <div className="tabs">
      <div className="tab">
        <input type="checkbox" id="chck1" />
        <label className="tab-label" htmlFor="chck1">
          Payment History{" "}
          <span className={styles.totalUnpaid}>{paymentRecords?.length}</span>
          <span className={styles.space}></span>
        </label>
        <div className="tab-content">
          <div>
            <table>
              <tbody>
                <tr>
                  <th style={{width:"25%", paddingLeft:"5%"}}>Date Paid</th>
                  <th style={{width:"25%"}}>Amount Paid</th>
                  <th style={{width:"25%"}}>Payment Method</th>
                  <th style={{width:"10%"}}>Delete</th>
                </tr>
                {paymentRecords?.map((record) => (
                  <tr key={record._id}>
                    <td style={{paddingLeft:"5%"}}>{moment(record.datePaid).format("MMM DD, YYYY")}</td>
                    <td>{toCommas(record.amountPaid)}</td>
                    <td>{record.paymentMethod}</td>

                    <td >
                      <IconButton onClick={() => deletePayHis(id, record._id)}>
                        <DeleteOutlineRoundedIcon
                          style={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
