/* eslint-disable */
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { useDispatch } from "react-redux";
import { createClient } from "../../actions/clientActions";
import { useLocation } from "react-router-dom";

import { useSnackbar } from "react-simple-snackbar";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#1976D2",
    marginLeft: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddClient = ({ setOpen, open }) => {
  const location = useLocation();
  const [clientData, setClientData] = useState({
    name: "",
    gstno: "",
    email: "",
    phone: "",
    floor: "",
    flatarea: "",
    price:"",
    block: "",
    flat: "",
    userId: [],
  });
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();

  useEffect(() => {
    var checkId = user?.result?._id;
    if (checkId !== undefined) {
      setClientData({ ...clientData, userId: [checkId] });
    } else {
      setClientData({ ...clientData, userId: [user?.result?.googleId] });
    }
  }, [location]);

  const handleSubmitClient = (e) => {
    e.preventDefault();

    if (clientData?.name !== "") {
      if (clientData?.email !== "") {
        dispatch(createClient(clientData, openSnackbar));
        clear();
        handleClose();
      }else{
        openSnackbar("Please enter email address");
      }
    } else {
      openSnackbar("Please enter the name");
    }
  };

  const clear = () => {
    setClientData({
      name: "",
      gstno: "",
      email: "",
      phone: "",
      floor: "",
      flatarea: "",
      price:"",
      block: "",
      flat: "",
      userId: [],
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const inputStyle = {
    display: "block",
    padding: "1.4rem 0.75rem",
    width: "100%",
    fontSize: "0.8rem",
    lineHeight: 1.25,
    color: "#55595c",
    backgroundColor: "#fff",
    backgroundImage: "none",
    backgroundClip: "padding-box",
    borderTop: "0",
    borderRight: "0",
    borderBottom: "1px solid #eee",
    borderLeft: "0",
    borderRadius: "3px",
    transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)",
  };

  const focus = {
    "input:focus, textarea:focus": {
      outline: "0",
      borderBottomColor: "#ffab00",
    },
  };

  return (
    <div>
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            style={{ paddingLeft: "20px", color: "white" }}
          >
            New Customer
          </DialogTitle>
          <DialogContent dividers>
            <div className="customInputs">
              <input
                placeholder="Name or Company name"
                style={inputStyle}
                name="name"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, name: e.target.value })
                }
                value={clientData.name}
                required
              />
              <input
                placeholder="GST number"
                style={inputStyle}
                name="gstno"
                type="text"
                autoCapitalize="on"
                onChange={(e) =>
                  setClientData({ ...clientData, gstno: e.target.value })
                }
                value={clientData.gstno}
                required
              />

              <input
                placeholder="Enter Email Adress"
                style={inputStyle}
                name="email"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, email: e.target.value })
                }
                value={clientData.email}
                required
              />
              <input
                placeholder="Enter Phone Number"
                style={inputStyle}
                name="phone"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, phone: e.target.value })
                }
                value={clientData.phone}
                required
              />
              <input
                placeholder="Enter Flat Area (sq.ft)"
                style={inputStyle}
                name="flatarea"
                type="number"
                onChange={(e) =>
                  setClientData({ ...clientData, flatarea: e.target.value })
                }
                value={clientData.flatarea}
                required
              />
              <input
                placeholder="Enter Price per Sq.ft"
                style={inputStyle}
                name="price"
                type="number"
                onChange={(e) =>
                  setClientData({ ...clientData, price: e.target.value })
                }
                required
                value={clientData.price}
              />
              <input
                placeholder="Enter Block"
                style={inputStyle}
                name="block"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, block: e.target.value })
                }
                value={clientData.block}
                required
              />
              <input
                placeholder="Enter Floor"
                style={inputStyle}
                name="floor"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, floor: e.target.value })
                }
                value={clientData.floor}
                required
              />
              <input
                placeholder="Enter Flat no."
                style={inputStyle}
                name="flat"
                type="text"
                onChange={(e) =>
                  setClientData({ ...clientData, flat: e.target.value })
                }
                value={clientData.flat}
                required
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleSubmitClient}
              variant="contained"
              style={{ marginRight: "25px" }}
            >
              Save Customer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default AddClient;
